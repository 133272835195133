import {UnixTime} from "./time";

export type PowerData =
{
    battery:  number,
    soc:      number,
    pv:       number,
    grid:     number,
    inverter: number,
    acLoad?:  number,
    dcLoad?:  number
}

export type Maybe<T> = T | undefined

export type Timestamped<T> = { time: UnixTime, value: T }

export type Pair<T> = [T, T]

export type Position  = { x: number, y: number }
export type Direction = { dx: number, dy: number }
export type Size = { width: number, height: number }

export type Rect = Position & Size

export type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export const asMutable = <T>(t: T) => (t as Mutable<T>);
export const asMutableArray = <T>(t: ReadonlyArray<T>) => (t as Array<T>);
export const cast = <T>(t: unknown) => (t as T);