import {Maybe, Timestamped} from "./types";
import {isDefined} from "./utils";

export type DataRecord = Record<string, number>

export type DataPoint    = Timestamped<Maybe<DataRecord>>
export type RecordSeries = Array<DataPoint>
export type PointSeries  = Array<Timestamped<Maybe<number>>>
export type DataSeries   = Array<Maybe<number>>

export function getPoints(recordSeries: RecordSeries, series: keyof DataRecord): PointSeries
{
    return recordSeries.map(p => ({time: p.time, value: isDefined(p.value) ? p.value[series] : undefined}))
}

export function getData(recordSeries: RecordSeries, series: keyof DataRecord): DataSeries
{
    return recordSeries.map(p => (isDefined(p.value) ? p.value[series] : undefined))
}
