export function pad(data: Uint8Array, length: number): Uint8Array
{
    if (length < data.byteLength)
        throw new RangeError("length")

    const padded = new Uint8Array(length)
    padded.set(data)

    return padded;
}

export function concat(left: Uint8Array, right: Uint8Array): Uint8Array
{
    const c = new Uint8Array(left.length + right.length);
    c.set(left);
    c.set(right, left.length);
    return c
}

export function toHexString(data: Uint8Array)
{
    return [...data].map(byteToHex).join('');
}

function byteToHex(b: number)
{
    return b.toString(16).padStart(2, "0");
}

const b64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

export function toBase64(data : Uint8Array) : string
{
    const byteLength = data.byteLength
    const base64LengthPadded = 4 * Math.ceil(byteLength / 3)
    const base64Length       = Math.ceil(byteLength / 3 * 4);

    const base64 = new Array<String>(base64LengthPadded)

    for (let i = 0, o = 0; i < byteLength;)
    {
        const x = data[i++]
        const y = data[i++] ?? 0
        const z = data[i++] ?? 0

        base64[o++] = b64Chars[x >>> 2]
        base64[o++] = b64Chars[(x << 4 | y >>> 4) & 63]
        base64[o++] = b64Chars[(y << 2 | z >>> 6) & 63]
        base64[o++] = b64Chars[z & 63]
    }

    for (let i = base64LengthPadded; i > base64Length ;)
        base64[--i] = "="

    return base64.join('')
}