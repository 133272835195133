import {canvasHeight, canvasWidth, pAcBus, pAcLoads, pBattery, pDcBus, pDcLoads, pGrid, pInverter, pPv} from "./constants";
import {moveElectrons} from "./electrons";
import {drawBox} from "./drawingPrimitives";
import {drawHorizontalPowerFlow, drawVerticalPowerFlow} from "./powerFlow";
import {S3Access} from "./S3/S3Access";
import {TimeSpan, UnixTime} from "./time";
import {PowerData} from "./types";
import {GraphLayout, drawGraph, GraphConfig, getDataWindowValueWidth} from "./graph";
import {animationFrameScheduler, combineLatest, combineLatestWith, distinctUntilChanged, filter, fromEvent, map, mergeWith, observeOn, pairwise, scan, shareReplay, startWith, tap, throttleTime, withLatestFrom} from "rxjs";
import DataCache, {FetchResult} from "./dataCache";
import {keepPixelPerfectCanvasDrawingSize} from "./canvas";
import {isDefined, useDefault} from "./utils";
import {observeMouseState} from "./mouseState";
import {RecordSeries} from "./data";
import {HslColor} from "./color";


// const fetcher = new Worker("fetcher.js");
// fetcher.addEventListener("message", e => { console.log(e.data) })
//
// fetcher.postMessage("hello")


const baseSampleInterval = TimeSpan.fromSeconds(2);

function createCanvas(elementId: string, width: number, height: number): CanvasRenderingContext2D
{
    const ratio = window.devicePixelRatio;
    const canvas = document.createElement("canvas");
    document.getElementById(elementId)!.appendChild(canvas);

    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";

    const context = canvas.getContext("2d")!;
    context.scale(ratio, ratio);

    return context
}

export const topologyCanvas : CanvasRenderingContext2D = createCanvas("topology", canvasWidth, canvasHeight)

// noinspection SpellCheckingInspection
const s3Access = new S3Access(
    "meiringen",
    "sos-ch-dk-2",
    "exo.io",
    "EXO36c6c169fbe39800742f4f56",
    "MA5svEdwwNF9XaLO-8PKj_P325Ovog5aeihPAc88R1w",
    ""
)

// const s3Access = new S3Access(
//     "meiringen",
//     "sos-ch-dk-2",
//     "exo.io",
//     "EXO4fe75ee679ab937613a6ad3f",
//     "ZvAds3uFry5FQ9l_j1STY1kHZ6AYwnzdgbKu6jJNfiA",
//     ""
// )


// const gridDevice = new Device("Grid", 'rgb(231,76,60)', 'rgb(192,57,43)');
// const grid = Bus.fromDevice(gridDevice)
//
// const pvInverter  = new Device("PV Inverter", 'rgb(244,179,80)' , 'rgb(243,156,18)')
// const acLoad      = new Device("AC Load"    , 'rgb(46,204,113)' , 'rgb(39,174,96)')
// const acBusDevice = new Device("AC Bus"     , 'rgb(173,173,173)', 'rgb(142,142,142)')
//
// const acBus = new Bus(acBusDevice, pvInverter, acLoad)
//
// const inverterDevice = new Device("Inverter",'rgb(71,137,208)', 'rgb(71,137,208)')
// const inverter       = Bus.fromDevice(inverterDevice)
//
// const mppt        = new Device("MPPT"   , 'rgb(244,179,80)' , 'rgb(243,156,18)')
// const dcLoad      = new Device("DC Load", 'rgb(46,204,113)' , 'rgb(39,174,96)')
// const dcBusDevice = new Device("DC Bus" , 'rgb(173,173,173)', 'rgb(142,142,142)')
//
// const dcBus = new Bus(dcBusDevice, mppt, dcLoad)
//
// const batteryDevice = new Device("Battery", 'rgb(166,199,229)' , 'rgb(81,143,211)')
// const battery = Bus.fromDevice(batteryDevice)
//
// grid.connect(acBus)
//     .connect(inverter)
//     .connect(dcBus)
//     .connect(battery)
//
//
//
// function renderNew()
// {
//     canvas.save()
//
//     canvas.fillStyle = 'rgb(222,222,222)';
//     canvas.fillRect(0, 0, canvasWidth, canvasHeight);
//
// //    canvas.translate(0,canvasHeight / 2)
//
//     console.log(grid.width, grid.height)
//
//     const sx = canvasWidth / grid.width
//     const sy = canvasHeight / grid.height
//
//     const s = Math.min(sx,sy)
//
//     canvas.scale(s, s)
//
//     canvas.translate(grid.width/6,grid.height/2)
//
//     grid.draw(canvas)
//     canvas.restore()
// }

let data: PowerData = {battery: 0, grid: 0, inverter: 0, pv: 0, soc: 0};

function render()
{
    moveElectrons();

    if (data.acLoad === undefined)
        console.log("no load")

    const acLoad = data.acLoad ? data.acLoad : data.grid - data.inverter
    const dcLoad = data.dcLoad ? data.dcLoad : data.inverter - data.battery + data.pv

    topologyCanvas.fillStyle = 'rgb(250,250,250)';
    topologyCanvas.fillRect(0, 0, canvasWidth, canvasHeight);

    topologyCanvas.font = '18px sans-serif';
    topologyCanvas.textBaseline = 'top';
    topologyCanvas.fillStyle = 'rgb(150,150,150)';
    topologyCanvas.fillText('DC Lab Meiringen', 10, 10);

    drawHorizontalPowerFlow(pGrid, data.grid);
    drawHorizontalPowerFlow(pInverter, data.inverter);
    drawHorizontalPowerFlow(pAcBus, data.inverter);
    drawHorizontalPowerFlow(pDcBus, data.battery);

    drawVerticalPowerFlow(pPv, data.pv);
    drawVerticalPowerFlow(pAcBus, acLoad);

    drawBox(pGrid    , .28, 'rgb(231,76,60)'  , 'rgb(192,57,43)'  , 'Grid'       , Math.round(data.grid) + "W")
    drawBox(pAcBus   , .28, 'rgb(173,173,173)', 'rgb(142,142,142)', 'AC Bus'     , "")
    drawBox(pAcLoads , .28, 'rgb(46,204,113)' , 'rgb(39,174,96)'  , 'AC Loads'   , Math.round(acLoad)+ "W")
    drawBox(pInverter, .28, 'rgb(71,137,208)' , 'rgb(71,137,208)' , 'Inverter'   , Math.round(data.inverter)+ "W")
    drawBox(pPv      , .28, 'rgb(244,179,80)' , 'rgb(243,156,18)' , 'PV'         , Math.round(data.pv) + "W")
    drawBox(pDcBus   , .28, 'rgb(173,173,173)', 'rgb(142,142,142)', 'DC Bus'     , "")
    drawBox(pDcLoads , .28, 'rgb(46,204,113)' , 'rgb(39,174,96)'  , 'DC Loads'   , Math.round(dcLoad)+ "W")
    drawBox(pBattery ,  1-data.soc/100, 'rgb(166,199,229)' , 'rgb(81,143,211)' , 'Battery' , Math.round(data.soc *10) / 10+ "%")
}

function now(): UnixTime
{
    return UnixTime
          .now()
          .move(- 2*baseSampleInterval.ticks)
          .round(baseSampleInterval)
}

function requestJson()
{
    const timestamp = now()

    s3Access.get(`${timestamp}`)
            .then<PowerData>(r => r.json())
            .then(d => data = d)
            .catch();
}


requestJson()

setInterval(requestJson, 2000)
setInterval(render, 50)

////////////////////////////////////////////////////////////////////////////

const canvasLayout: GraphLayout =
{
    yAxisWidth : 80,
    xAxisHeight: 50,
    titleHeight: 50,
    legendWidth: 40,
    dataHeight: 300,
    graphPixelsPerPoint: 4
}

const initialEndTime = UnixTime.now().round(TimeSpan.fromSeconds(2))
const graphCanvasElement = document.getElementById("graph") as HTMLCanvasElement
const cache = new DataCache<PowerData>(fetchData, baseSampleInterval)
const initialZoomLevel = 30;
const zoomFactor = 1.2

function fetchData(timestamp: UnixTime): Promise<FetchResult<PowerData>>
{
    const s3Path = `${(timestamp.ticks)}`;

    return s3Access.get(s3Path)
                   .then<PowerData>(r =>
                   {
                       if (r.status === 404)
                       {
                           return Promise.resolve("Not Available");
                       }
                       else if (r.status === 200)
                       {
                           return r.json();
                       }
                       else
                       {
                           console.error("unexpected status code")
                           return Promise.resolve("Not Available");
                       }
                   })
                   .catch(e =>
                   {
                       console.log(e);
                       return "Try Later";
                   });

}

const graphCanvas$ = keepPixelPerfectCanvasDrawingSize(graphCanvasElement)
const mouseState$  = observeMouseState(graphCanvasElement);

const scrollWheel$ = fromEvent<WheelEvent>(graphCanvasElement, "wheel").pipe
(
    tap(e => e.preventDefault()),
    map(e => Math.sign(e.deltaY)),
    startWith(0),
    shareReplay(1),
);

const zoomLevel$ = scrollWheel$.pipe
(
    scan((z, d) => Math.max(0, z + d), initialZoomLevel),
    startWith(initialZoomLevel),
    distinctUntilChanged(),
)

const graphSampleInterval$ = zoomLevel$.pipe
(
    map(z => baseSampleInterval.multiply(Math.round(Math.pow(zoomFactor, z)))),
    shareReplay(1),
)

const nPoints$ = graphCanvas$.pipe(map(cs => getDataWindowValueWidth(cs, canvasLayout)))

const newData$ = cache.gotData.pipe
(
    startWith(initialEndTime.ticks)
);

const graphSpan$ = combineLatest([graphSampleInterval$, nPoints$]).pipe
(
    map(([graphSampleInterval, nPoints]) => graphSampleInterval.multiply(nPoints)),
    shareReplay(1)
)

const mouseX$ = mouseState$.pipe
(
    map(ms => useDefault(ms?.position.x, -1)),
    startWith(-1),
)

const deltaSpan$ = graphSpan$.pipe
(
    pairwise(),
    map(([before, after]) => after.ticks - before.ticks),
    startWith(0),
)

const panFromZoom$ = combineLatest([deltaSpan$, graphCanvas$]).pipe
(
    withLatestFrom(mouseX$),  // this must come after the above two!
    map(([[deltaSpan, canvasSize], xMarker]) => (canvasSize.width - xMarker) / canvasSize.width * deltaSpan),
    withLatestFrom(graphSampleInterval$),
    map(([dx, i]) => Math.round(dx / i.ticks) * i.ticks)
)

const pan$ = mouseState$.pipe
(
    filter(m => isDefined(m) && m.button1.dragged),
    map(m => Math.round(m!.movement.dx / canvasLayout.graphPixelsPerPoint)),
    combineLatestWith(graphSampleInterval$),
    map(([m, i]) => -m * i.ticks),
    mergeWith(panFromZoom$),
    startWith(0),
)

const endTime$ = pan$.pipe
(
    scan((t, dt) => t.move(dt), initialEndTime),
    startWith(initialEndTime),
    withLatestFrom(graphSampleInterval$),
    map(([t, i]) => t.round(i)),
)

const graphRange$ = combineLatest([endTime$, graphSpan$]).pipe
(
    map(([endTime, graphSpan]) => endTime.rangeBefore(graphSpan as TimeSpan)),
)

const samplePoints$ = combineLatest([graphRange$, graphSampleInterval$]).pipe
(
    map(([graphRange, graphSampleInterval]) =>
    {
        const points = graphRange.sample(graphSampleInterval)
        cache.prefetch(points)

        // const earlier = graphRange.earlier(graphRange.duration).sample(graphSampleInterval)
        // cache.prefetch(earlier, false)

        // const later = graphRange.later(graphRange.duration).sample(graphSampleInterval).filter(t=>!t.isInTheFuture())
        // cache.prefetch(later, false)

        return points
    })

)

const samples$ = combineLatest([samplePoints$, newData$]).pipe
(
    map(([samplePoints]) => samplePoints.map(time => ({time, value: cache.get(time)})) as RecordSeries)
)

combineLatest([samples$, graphCanvas$, mouseX$]).pipe
(
    throttleTime(30),
    observeOn(animationFrameScheduler)
)
.subscribe(([samples, graphCanvas, mouseX]) =>
{
    clearCanvas(graphCanvas);

    const config : GraphConfig = {
        canvas: graphCanvas,
        records: samples,
        mouseX: mouseX,
        layout: canvasLayout
    }

    const color : HslColor = {h: 222, s: .5, l:.5}

    drawGraph(config, "soc", "%", color, 0, 0, 100);
    drawGraph(config, "battery", "W", {...color, h: 150, l:.4}, 1, -4000, 4000);
    drawGraph(config, "grid", "W", {...color, h: 0}, 2, 0, 10000);
    drawGraph(config, "acLoad", "W", {...color, h: 35}, 3, 0, 10000);
})


function clearCanvas(canvas : HTMLCanvasElement)
{
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D

    ctx.fillStyle = 'rgb(240,240,240)'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
}