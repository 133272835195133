import {minElectronSpeed, nElectrons, pi2, rElectron, tMax, wFlow} from "./constants";
import {topologyCanvas} from "./main";

type Electron = { t: number, w: number, speed: number }
export const electrons: Electron[] = []

for (let i = 0; i < nElectrons; i++)
{
    electrons.push(createElectron())
}

function createElectron(): Electron
{
    return {
        t: Math.random() * tMax,
        w: Math.random() * wFlow,
        speed: Math.random() + minElectronSpeed,
    }
}

export function moveElectrons()
{
    for (const e of electrons)
        e.t = (e.t + e.speed) % tMax;
}

export function drawElectron(c: CanvasRenderingContext2D, electron: Electron, ex: number, ey: number)
{
    const alpha = (electron.speed - minElectronSpeed) * .8 + .2;

    c.fillStyle = `rgba(255,182,49, ${alpha})`
    c.beginPath();
    c.arc(ex + rElectron, ey + rElectron, rElectron, 0, pi2, false);
    c.fill();
}

