import {sha1Hmac} from "./Sha1";
import {Utf8} from "./Utf8";
import {toBase64} from "./UInt8Utils";

export class S3Access
{
    constructor
    (
        readonly bucket:      string,
        readonly region:      string,
        readonly provider:    string,
        readonly key:         string,
        readonly secret:      string,
        readonly contentType: string
    )
    {}

    get host() : string { return `${this.bucket}.${this.region}.${this.provider}` }
    get url()  : string { return `https://${this.host}` }

    public get(s3Path : string): Promise<Response>
    {
        const method = "GET";
        const auth    = this.createAuthorizationHeader(method, s3Path, "");
        const url     = this.url + "/" + s3Path
        const headers = {"Host": this.host, "Authorization": auth};

        try
        {
            return fetch(url, {method: method, mode: "cors", headers: headers})
        }
        catch
        {
            return Promise.reject()
        }
    }

    private createAuthorizationHeader(method: string,
                                      s3Path: string,
                                        date: string)
    {
        return createAuthorizationHeader
                       (
                           method,
                           this.bucket,
                           s3Path,
                           date,
                           this.key,
                           this.secret,
                           this.contentType
                       );
    }
}

function createAuthorizationHeader(method: string,
                                   bucket: string,
                                   s3Path: string,
                                     date: string,
                                    s3Key: string,
                                 s3Secret: string,
                              contentType: string,
                                  md5Hash: string = "")
{
    // StringToSign = HTTP-Verb + "\n" +
    //                Content-MD5 + "\n" +
    //                Content-Type + "\n" +
    //                Date + "\n" +
    //                CanonicalizedAmzHeaders +
    //                CanonicalizedResource;

    const payload   = Utf8.encode(`${method}\n${md5Hash}\n${contentType}\n${date}\n/${bucket}/${s3Path}`)

    //console.log(`${method}\n${md5Hash}\n${contentType}\n${date}\n/${bucket}/${s3Path}`)

    const secret    = Utf8.encode(s3Secret)
    const signature = toBase64(sha1Hmac(payload, secret));

    return `AWS ${s3Key}:${signature}`
}
