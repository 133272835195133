import {find, findPath, insert, Path, SkipListNode} from "./skipListNode";

export class SkipList<T>
{
    public readonly head: SkipListNode<T>;
    public readonly tail: SkipListNode<T>;

    private readonly nLevels: number;

    private _length = 0

    constructor(nLevels: number = 20)
    {
        // TODO: auto-levels

        this.tail =
        {
            index: Number.MAX_VALUE,
            next: [],
            value: undefined!
        };

        this.head =
        {
            index: Number.MIN_VALUE,
            next: Array(nLevels).fill(this.tail),
            value: undefined!
        };

        this.nLevels = nLevels
    }

    public find(index: number, startNode = this.head, endNode = this.tail): SkipListNode<T>
    {
        return find(index, startNode, endNode)
    }

    private findPath(index: number, startNode = this.head, endNode = this.tail): Path<T>
    {
        return findPath(index, startNode, endNode)
    }

    public insert(value: T, index: number): SkipListNode<T>
    {
        const path = this.findPath(index)
        const node = path[0];

        if (node.index === index)  // overwrite
        {
            node.value = value
            return node
        }

        const nodeToInsert = {value, index, next: []} as SkipListNode<T>

        const rnd = (Math.random() * (1 << this.nLevels)) << 0;

        for (let level = 0; level < this.nLevels; level++)
        {
            insert(nodeToInsert, path[level], level)

            if ((rnd & (1 << level)) === 0)
                break
        }

        this._length += 1

        return nodeToInsert;
    }

    get length(): number
    {
        return this._length;
    }

    // public remove(index: number): void
    // {
    //     // TODO
    // }
}
