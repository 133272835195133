import {asMutableArray} from "../types";

export type Next<T> = { readonly next: ReadonlyArray<SkipListNode<T>> }
export type Index = { readonly index: number }
export type Indexed<T> = Index & { value: T }
export type SkipListNode<T> = Next<T> & Indexed<T>
export type Path<T> = SkipListNode<T>[];

export function find<T>(index: number, startNode: SkipListNode<T>, endNode: SkipListNode<T>): SkipListNode<T>
{
    let node = startNode

    for (let level = startNode.next.length - 1; level >= 0; level--)
        node = findOnLevel(index, node, endNode, level)

    return node
}

export function findOnLevel<T>(index: number, startNode: SkipListNode<T>, endNode: SkipListNode<T>, level: number): SkipListNode<T>
{
    let node: SkipListNode<T> = startNode

    while (true)
    {
        const next = node.next[level]

        if (index < next.index || endNode.index < next.index)
            return node

        node = next
    }
}

export function findPath<T>(index: number, startNode: SkipListNode<T>, endNode: SkipListNode<T>): Path<T>
{
    const path = Array(startNode.next.length - 1)
    let node = startNode

    for (let level = startNode.next.length - 1; level >= 0; level--)
    {
        node = findOnLevel(index, node, endNode, level)
        path[level] = node
    }

    return path
}

export function insert<T>(nodeToInsert: SkipListNode<T>, after: SkipListNode<T>, onLevel: number): void
{
    asMutableArray(nodeToInsert.next)[onLevel] = after.next[onLevel]
    asMutableArray(after.next)[onLevel] = nodeToInsert
}