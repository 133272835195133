import {fromEvent, map, mapTo, merge, Observable, pairwise, share, startWith, tap} from "rxjs";
import {Direction, Maybe, Pair, Position} from "./types";
import {isDefined} from "./utils";

export type MouseButtonState =
{
    down: boolean;
    dragged: boolean;
}

export type MouseState =
{
    readonly position: Position;
    readonly movement: Direction;

    readonly button1: MouseButtonState;
    readonly button2: MouseButtonState;
    readonly button3: MouseButtonState;
}

type PartialMouseState =
{
    position: { x: number; y: number };
    button1: boolean
    button2: boolean;
    button3: boolean;
};

function parsePartialMouseStatePixelPerfect(mouseEvent: MouseEvent): PartialMouseState
{
    const ratio = window.devicePixelRatio
    return {
        position: {x: mouseEvent.clientX * ratio, y: mouseEvent.clientY * ratio},
        button1:  (mouseEvent.buttons & 1) !== 0,
        button2:  (mouseEvent.buttons & 2) !== 0,
        button3:  (mouseEvent.buttons & 4) !== 0,
    }
}

function parsePartialMouseState(mouseEvent: MouseEvent): PartialMouseState
{
    return {
        position: {x: mouseEvent.clientX, y: mouseEvent.clientY},
        button1:  (mouseEvent.buttons & 1) !== 0,
        button2:  (mouseEvent.buttons & 2) !== 0,
        button3:  (mouseEvent.buttons & 4) !== 0,
    }
}

function parseMouseState([before, now]: Pair<PartialMouseState| undefined>): Maybe<MouseState>
{
    if (!isDefined(now))
        return undefined

    const hasBefore = isDefined(before);

    const movement = hasBefore
                     ? {dx: now.position.x - before.position.x, dy: now.position.y - before.position.y}
                     : {dx: 0, dy: 0}

    const dragged1 = hasBefore && before.button1 && now.button1
    const dragged2 = hasBefore && before.button2 && now.button2
    const dragged3 = hasBefore && before.button3 && now.button3

    return {
        position: now.position,
        movement,
        button1: {down: now.button1, dragged: dragged1},
        button2: {down: now.button2, dragged: dragged2},
        button3: {down: now.button3, dragged: dragged3},
    }
}

export function observeMouseState(element: Element, pixelPerfect: boolean = true): Observable<Maybe<MouseState>>
{
    const parse = pixelPerfect ? parsePartialMouseStatePixelPerfect : parsePartialMouseState

    const mouseMove  = fromEvent<MouseEvent>(element, "mousemove" ).pipe(map(e => parse(e)))
    const mouseEnter = fromEvent<MouseEvent>(element, "mouseenter").pipe(map(e => parse(e)))
    const mouseLeave = fromEvent<MouseEvent>(element, "mouseleave").pipe(mapTo(undefined), startWith(undefined))

    return merge(mouseEnter, mouseMove, mouseLeave).pipe
    (
        pairwise(),
        map((x) => parseMouseState(x)),
        share()
    );
}