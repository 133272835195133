import {map, mapTo, Observable, share, startWith, tap} from "rxjs";
import {isDefined} from "./utils";
import {Size} from "./types";


type Entry = ResizeObserverEntry & { readonly devicePixelContentBoxSize?: ReadonlyArray<ResizeObserverSize> }

function getSizeFromBoundingClientRect(target: HTMLCanvasElement) : Size
{
    const size = target.getBoundingClientRect();
    const ratio = window.devicePixelRatio;

    const width = Math.floor(size.width * ratio);
    const height = Math.floor(size.height * ratio);

    return {width, height};
}

function getSizeFromDevicePixelContentBoxSize(entries: ResizeObserverEntry[]) : Size | undefined
{
    const entry = entries[0] as Entry

    if (!isDefined(entry))
        return undefined

    const pixelSize = entry.devicePixelContentBoxSize;

    if (!isDefined(pixelSize))
        return undefined;

    const pixelSize0 = pixelSize[0]
    if (!isDefined(pixelSize0))
        return undefined;

    const width  = pixelSize0.inlineSize;
    const height = pixelSize0.blockSize;

    return {width, height}
}

function getSizeFromContentBoxSize(entries: ResizeObserverEntry[]) : Size | undefined
{
    const entry = entries[0] as Entry

    if (!isDefined(entry))
        return undefined

    const ratio = window.devicePixelRatio;
    const pixelSize = entry.contentBoxSize;

    if (!isDefined(pixelSize))
        return undefined;

    const pixelSize0 = pixelSize[0]
    if (!isDefined(pixelSize0))
        return undefined;

    const width  = pixelSize0.inlineSize * ratio;
    const height = pixelSize0.blockSize * ratio;

    return {width, height}
}


function resizeObservable(elem : Element)
{
    return new Observable<ResizeObserverEntry[]>(subscriber =>
    {
        const ro = new ResizeObserver(entries => subscriber.next(entries));
        ro.observe(elem);
        return () => ro.unobserve(elem)
    });
}

function getSize(entries: ResizeObserverEntry[]): Size
{
    return getSizeFromDevicePixelContentBoxSize(entries) ||
           getSizeFromContentBoxSize(entries) ||
           getSizeFromBoundingClientRect(entries[0].target as HTMLCanvasElement)
}



export function observeCanvasDrawingSize(canvas: HTMLCanvasElement): Observable<Size>
{
    return resizeObservable(canvas).pipe
    (
        map(getSize),
        startWith(getSizeFromBoundingClientRect(canvas))
    )
}

export function keepPixelPerfectCanvasDrawingSize(canvas: HTMLCanvasElement): Observable<HTMLCanvasElement>
{
    return observeCanvasDrawingSize(canvas).pipe
    (
        tap(s =>
        {
            canvas.width = s.width;
            canvas.height = s.height
        }),
        mapTo(canvas),
        share()
    )
}

export class GraphCanvas
{
    private constructor(private readonly canvasElement: HTMLCanvasElement,
                        private readonly ctx: CanvasRenderingContext2D,
                        private readonly scaleX: number = 1,
                        private readonly scaleY: number = 1,
                        private readonly translateX: number = 0,
                        private readonly translateY: number = 0,
    )
    {

    }

    public static fromCanvasElement(canvasElement: HTMLCanvasElement): GraphCanvas
    {
        const ctx = canvasElement.getContext("2d") as CanvasRenderingContext2D
        return new GraphCanvas(canvasElement, ctx)
    }

    public transform(scaleX: number, scaleY: number, translateX: number, translateY: number): GraphCanvas
    {
        return new GraphCanvas(this.canvasElement,
                               this.ctx,
                               this.scaleX * scaleX,
                               this.scaleY * scaleY,
                               this.translateX + translateX * this.scaleX,
                               this.translateY + translateY * this.scaleY)
    }



    public clear(bgStyle?: string | CanvasGradient | CanvasPattern )
    {
        if (isDefined(bgStyle))
        {
            this.ctx.fillStyle = bgStyle
            this.ctx.fillRect(0, 0, this.canvasElement.width, this.canvasElement.height)
        }
        else
        {
            this.ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height)
        }
    }


    public beginPath()
    {
        this.ctx.beginPath()
    }

    public closePath()
    {
        this.ctx.closePath()
    }

    public moveTo(x: number, y: number)
    {
        this.ctx.moveTo(this.transformX(x), this.transformY(y))
    }

    public lineTo(x: number, y: number)
    {
        this.ctx.lineTo(this.transformX(x), this.transformY(y))
    }

    public fill()
    {
        this.ctx.fill();
    }

    public stroke()
    {
        this.ctx.stroke();
    }

    public save()
    {
        this.ctx.save();
    }

    public restore()
    {
        this.ctx.restore();
    }

    public set fillStyle(style: string | CanvasGradient | CanvasPattern)
    {
        this.ctx.fillStyle = style
    }

    public get fillStyle() : string | CanvasGradient | CanvasPattern
    {
        return this.ctx.fillStyle
    }

    public set strokeStyle(style: string | CanvasGradient | CanvasPattern)
    {
        this.ctx.strokeStyle = style
    }

    public get strokeStyle() : string | CanvasGradient | CanvasPattern
    {
        return this.ctx.strokeStyle
    }

    public set lineWidth(width: number)
    {
        this.ctx.lineWidth = width
    }

    public get lineWidth() : number
    {
        return this.ctx.lineWidth
    }

    public strokeCircle(x: number, y: number, radius: number, stroke?: string | CanvasGradient | CanvasPattern)
    {
        if (isDefined(stroke))
            this.ctx.strokeStyle = stroke

        this.ctx.beginPath();
        this.ctx.ellipse(this.transformX(x), this.transformY(y), radius, radius, 0, 0, 2 * Math.PI)
        this.ctx.stroke();
    }

    public fillCircle(x: number, y: number, radius: number, fill?: string | CanvasGradient | CanvasPattern)
    {
        if (isDefined(fill))
            this.ctx.fillStyle = fill

        this.ctx.beginPath();
        this.ctx.ellipse(this.transformX(x), this.transformY(y), radius, radius, 0, 0, 2 * Math.PI)
        this.ctx.fill();
    }


    public fillRect(x: number, y: number, w: number, h: number)
    {
        this.ctx.fillRect(this.transformX(x), this.transformY(y), w, h)
    }

    public strokeRect(x: number, y: number, w: number, h: number)
    {
        this.ctx.strokeRect(this.transformX(x), this.transformY(y), w, h)
    }

    public transformX(x: number)
    {
        return x * this.scaleX + this.translateX
    }

    public transformY(y: number)
    {
        return y * this.scaleY + this.translateY
    }

    public transformBackX(x: number)
    {
        return (x - this.translateX) / this.scaleX
    }

    public transformBackY(y: number)
    {
        return (y - this.translateY) / this.scaleY
    }
}