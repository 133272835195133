

export function createDispatchQueue(maxInflight: number, debug = false): { dispatch: (task: () => Promise<void>) => number; clear: () => void }
{
    const queue: Array<() => Promise<void>> = []

    let inflight = 0;

    function done()
    {
        inflight--

        if (debug && inflight + queue.length === 0)
            console.log("queue empty")

        if (inflight < maxInflight && queue.length > 0)
        {
            const task = queue.pop()!
            inflight++
            task().finally(() => done())
        }
    }

    function dispatch(task: () => Promise<void>) : number
    {
        if (inflight < maxInflight)
        {
            inflight++;
            task().finally(() => done())
        }
        else
        {
            if (debug && queue.length === 0)
                console.log("queue in use")

            queue.push(task)
        }

        return queue.length
    }

    function clear()
    {
        // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
        queue.length = 0
        if (debug)
            console.log("queue cleared")
    }

    return {dispatch, clear}
}