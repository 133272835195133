import {Maybe} from "./types";

export type Nothing = Record<string, never>
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const nop = <T>(_?: T) => {};
export const id  = <T>(e: T) => e;

export function isDefined<T>(e: T | undefined): e is T
{
    return e !== undefined
}

export function isUndefined<T>(e: Maybe<T>): e is undefined
{
    return e === undefined
}

export function useDefault<T>(e: Maybe<T>, defaultValue: T): T
{
    return isDefined(e)
           ? e
           : defaultValue
}

export function last<T>(a: Array<T>) : T
{
    return a[a.length - 1]
}

export function arraysEqual<T>(a: Array<T>, b: Array<T>)
{
    if (a === b) return true;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i)
    {
        if (a[i] !== b[i]) return false;
    }

    return true;
}

export function isDST(d : Date)
{
    const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();

    return Math.max(jan, jul) != d.getTimezoneOffset();
}
