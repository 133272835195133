import {flowColor, hBox, hFlow, wBox, wFlow, rElectron} from "./constants";
import {drawRectangle} from "./drawingPrimitives";
import {drawElectron, electrons} from "./electrons";
import {Position} from "./types";
import {topologyCanvas} from "./main";

let knownMax = 1;  // avoid division by 0

function normalize(flow: number)
{
    const reverse = flow < 0;
    flow = Math.abs(flow)
    knownMax = Math.max(knownMax, flow)
    flow = flow / knownMax * (hBox - 10)

    return {flow, reverse}
}


export function drawHorizontalPowerFlow(position: Position, flowAmount: number)
{
    let {flow, reverse} = normalize(flowAmount);

    const {x, y} = position;

    const xR = x + wBox;
    const yR = y + hBox / 2 - flow / 2;

    drawRectangle(xR, yR, wFlow, flow, flowColor)

    for (let electron of electrons)
    {
        if (electron.w > flow - 2 * rElectron)
            continue;

        let position = electron.t % wFlow;

        if (reverse)
            position = wFlow - position

        const ex = xR + position;
        const ey = yR + electron.w;

        drawElectron(topologyCanvas, electron, ex, ey);
    }
}

export function drawVerticalPowerFlow(position: Position, flowAmount: number)
{
    let {flow, reverse} = normalize(flowAmount);
    const {x, y} = position;

    const xR = x + wBox / 2 - flow / 2;
    const yR = y + hBox;

    drawRectangle(xR, yR, flow, hFlow, flowColor)

    for (let electron of electrons)
    {
        if (electron.w > flow - 2 * rElectron)
            continue;

        let position = electron.t % wFlow;

        if (reverse)
            position = wFlow - position

        const ex = xR + electron.w;
        const ey = yR + position;

        drawElectron(topologyCanvas, electron, ex, ey);
    }
}