import {isDefined, isUndefined, last} from "./utils";
import TransformedDrawingContext from "./transformedDrawingContext";
import {Maybe, Size} from "./types";
import {DataRecord, getPoints, PointSeries, RecordSeries} from "./data";
import {HslColor, toCssColor} from "./color";
import {UnixTime} from "./time";
import dateFormat from "dateformat";


const minute = 60;
const hour = 60*minute;
const day = 24*hour;

const timeDivisions: Array<{ ticks: number; format: string }> =
[
    { ticks: 1,         format : "HH:MM:ss" },
    { ticks: 10,        format : "HH:MM:ss" },
    { ticks: minute,    format : "HH:MM" },
    { ticks: 5*minute,  format : "HH:MM" },
    { ticks: 10*minute, format : "HH:MM" },
    { ticks: 15*minute, format : "HH:MM" },
    { ticks: 30*minute, format : "HH:MM" },
    { ticks: hour,      format : "HH:MM" },
    { ticks: 2*hour,    format : "HH:MM" },
    { ticks: 3*hour,    format : "HH:MM" },
    { ticks: 6*hour,    format : "HH:MM" },
    { ticks: 12*hour,   format : "HH:MM" },
    { ticks: day,       format : "dd.mm" },
    { ticks: 7*day,     format : "dd.mm" }
]


const valueDivisions = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000]

const minPixelsPerTDiv = 100;
const minPixelsPerValueDiv = 40;

export type GraphConfig =
{
    canvas: HTMLCanvasElement,
    records: RecordSeries,
    mouseX: number,
    layout: GraphLayout,
}

export type GraphLayout =
{
    yAxisWidth : number,
    legendWidth: number,
    xAxisHeight: number,
    titleHeight: number,
    dataHeight: number,
    graphPixelsPerPoint: number,
}

export function getDataWindowValueWidth(canvas: HTMLCanvasElement, layout: GraphLayout) : number
{
    const width = canvas.width - layout.legendWidth - layout.yAxisWidth ;
    return Math.ceil(width / layout.graphPixelsPerPoint)
}

export function getGraphPixelSize(canvas: HTMLCanvasElement, layout: GraphLayout) : Size
{
    const width  = canvas.width;
    const height = layout.dataHeight + layout.titleHeight + layout.xAxisHeight;

    return {width, height}
}

export function getDataWindowPixelSize(canvas: HTMLCanvasElement, layout: GraphLayout) : Size
{
    const width  = canvas.width - layout.legendWidth - layout.yAxisWidth ;
    const height = layout.dataHeight;

    return {width, height}
}


function getYRange(data: PointSeries, yMin?: number, yMax?: number): { min: number; max: number }
{
    if (isDefined(yMin) && isDefined(yMax))
        return {min: yMin, max: yMax}  // easy out

    const values = data.filter(d => isDefined(d.value)).map(d=>d.value) as Array<number>

    const hasValues = values.length > 0

    const min = isDefined(yMin) ? yMin
              : hasValues ? Math.min(...values) * 1.1
              : 0

    const max = isDefined(yMax) ? yMax
              : hasValues ? Math.max(...values) * 1.1
              : 1

    return {min, max};
}

function getXRange(data: PointSeries): { xMin: UnixTime; xMax: UnixTime }
{
    return {xMin: data[0].time, xMax: data[data.length - 1].time}
}







export function drawGraph(config: GraphConfig,
                          series: keyof DataRecord,
                            unit: string,
                           color: HslColor,
                         graphId: number,
                           yMin?: number,
                           yMax?: number)
{


    const {canvas, layout, mouseX, records} = config;

    const points = getPoints(records, series)
    const yRange = getYRange(points, yMin, yMax)

    const dataWindowSize = getDataWindowPixelSize(canvas, layout);

    const dataCtx = createDataDrawingContext();
    const graphCtx = createGraphDrawingContext();

    dataCtx.save()

    graphCtx.fillStyle = `rgba(255, 255, 255, .9)`
    graphCtx.fillRect(layout.yAxisWidth, layout.titleHeight, dataWindowSize.width, dataWindowSize.height)

    drawTitle();
    drawXAxis();
    drawYAxis();
    drawData();
    drawXMarker();

    dataCtx.restore()

    return

    function drawTitle()
    {
        graphCtx.save()

        graphCtx.textAlign    = "center"
        graphCtx.textBaseline = "middle"
        graphCtx.fillStyle    = `rgba(240, 240, 240)`
        graphCtx.fillStyle    = `rgba(80, 80, 80)`
        graphCtx.font         = "24px Roboto,Helvetica,Arial,sans-serif"

        graphCtx.drawText(series.toUpperCase(),
                          canvas.width/2,
                          layout.titleHeight/2);

        graphCtx.restore()
    }

    function verticalLine(x: number, color: string)
    {
        dataCtx.strokeStyle = color
        dataCtx.lineWidth = .5

        dataCtx.beginPath()
        dataCtx.moveTo(x, yRange.min)
        dataCtx.lineTo(x, yRange.max)
        dataCtx.closePath()
        dataCtx.stroke();
    }

    function horizontalLine(y: number, color: string)
    {
        dataCtx.strokeStyle = color
        dataCtx.lineWidth = .5

        dataCtx.beginPath()
        dataCtx.moveTo(0, y)
        dataCtx.lineTo(points.length, y)
        dataCtx.closePath()
        dataCtx.stroke();
    }

    function drawXMarker()
    {
        if (isUndefined(mouseX))
            return;

        const hoverPoint = Math.round((mouseX - layout.yAxisWidth) / layout.graphPixelsPerPoint)
        if (hoverPoint < 0 || hoverPoint > points.length)
            return;

        dataCtx.save()

        dataCtx.lineWidth = 2
        dataCtx.textAlign = "left"
        dataCtx.textBaseline = "middle"

        verticalLine(hoverPoint, `rgba(255, 0, 0, .8)`);

        const point = points[hoverPoint];
        if (isUndefined(point))
            return;

        const y = point.value
        const date = point.time.toDate()
        const normalFont = "16px Roboto,Helvetica,Arial,sans-serif";
        const boldFont = "bold " + normalFont;

        const dh = (yRange.max - yRange.min)/15  // TODO



        if (isDefined(y))
        {
            const timeText = date.toLocaleTimeString("de-CH");
            const dateText = date.toLocaleDateString("de-CH");
            const valueText = series + ": " + y.toFixed(1) + unit;

            const mText = dataCtx.measureText(timeText)
            const mDateText = dataCtx.measureText(dateText)
            const mValueText = dataCtx.measureText(valueText)

            const w = Math.max(mText.width, mDateText.width, mValueText.width)

            dataCtx.strokeStyle = toCssColor(color)
            dataCtx.fillStyle = `rgb(240,240,240)`
            dataCtx.fillRect(hoverPoint + 4, y + dh * 1.7, w * 1.2, 65)  // TODO
            dataCtx.strokeRect(hoverPoint + 4, y + dh * 1.7, w * 1.2, 65)  // TODO

            dataCtx.strokeStyle = toCssColor(color)
            dataCtx.lineWidth = 1.5;
            dataCtx.strokeCircle(hoverPoint, y, 2.2)
            dataCtx.fillStyle = toCssColor(color)

            dataCtx.font = boldFont

            dataCtx.drawText(valueText, hoverPoint + 6, y + dh)
            dataCtx.font = normalFont

            dataCtx.drawText(timeText, hoverPoint + 6, y)
            dataCtx.drawText(dateText, hoverPoint + 6, y - dh)
        }
        dataCtx.restore()
    }

    function drawYAxis()
    {
        const nDivisions = dataWindowSize.height / minPixelsPerValueDiv
        const idealYDiv  = (yRange.max - yRange.min) / nDivisions
        const yDiv       = valueDivisions.find(n => n >= idealYDiv) || last(valueDivisions)

        dataCtx.textAlign = "right"
        dataCtx.textBaseline = "middle"
        for (let y = yRange.min; y <= yRange.max; y += yDiv)
        {
            dataCtx.drawText(y + unit, -2, y)
            horizontalLine(y, `rgba(0, 0, 0, .15)`)
        }
    }

    function drawData()
    {
        dataCtx.save()

        dataCtx.textAlign = "left"
        dataCtx.textBaseline = "alphabetic"
        dataCtx.strokeStyle = toCssColor(color)
        dataCtx.fillStyle = toCssColor({...color, a: .3})
        dataCtx.lineWidth = 1.5

        let prev: { x: number; y: Maybe<number> } = {x: -1, y: undefined};

        for (let x = 0; x < points.length; x++)
        {
            const y = clamp(points[x].value)

            if (isDefined(prev.y) && isDefined(y))
            {
                dataCtx.lineTo(x, y)
            }
                // else if (isDefined(prev.y) && isUndefined(y))     /// BEAUTY HACK
                // {
                //     //t.lineTo(prev.x, 0)    // normal (correct) drawing.
                //
                //     t.lineTo(x-1, prev.y)   // optimistically fill potential holes while still fetching
                //     t.lineTo(x-1, 0)
                //
                //     t.fill();
                //     t.stroke();
            // }
            else if (isUndefined(prev.y) && isDefined(y))
            {
                dataCtx.beginPath();

                if (prev.x >= 0) // optimistically fill potential holes
                {
                    dataCtx.moveTo(prev.x + 1, 0)
                    dataCtx.lineTo(prev.x + 1, y)
                    dataCtx.lineTo(x, y)
                }
                else // normal (correct) drawing. Must do so for x < 0 otherwise first value is extended to minus inf
                {
                    dataCtx.moveTo(x, 0)
                    dataCtx.lineTo(x, y)
                }
            }
            else
                continue

            prev = {x, y}
        }

        /// final close
        if (isDefined(prev.y))
        {
            dataCtx.lineTo(prev.x, 0)
            dataCtx.fill();
            dataCtx.stroke();
        }

        dataCtx.restore()
    }

    function clamp(y: Maybe<number>) : Maybe<number>
    {
        return isDefined(y)
             ? Math.max(Math.min(y, yRange.max), yRange.min)
             : undefined;
    }

    function drawXAxis()
    {
        const xRange   = getXRange(points)
        const nXTicks  = dataWindowSize.width / minPixelsPerTDiv
        const tPeriod  = xRange.xMax.ticks - xRange.xMin.ticks
        const idealDiv = tPeriod / nXTicks
        const tDiv     = timeDivisions.find(n => n.ticks >= idealDiv) || last(timeDivisions);

        const dateTimes = points.map(p =>
            {
                const offset = p.time.toDate().getTimezoneOffset() * 60

                // What a PITA! (it doesn't work yet)
                const epoch = Math.round((p.time.ticks - offset) / tDiv.ticks) * tDiv.ticks + offset;
                const date = new Date(epoch * 1000)

                return {mod: (p.time.ticks + offset) % tDiv.ticks, date}
            }
        )

        dataCtx.lineWidth = 1
        dataCtx.font = "16px Roboto,Helvetica,Arial,sans-serif"
        dataCtx.fillStyle = `rgba(80, 80, 80)`
        dataCtx.textAlign = "center"
        dataCtx.textBaseline = "top"

        const h = (yRange.max - yRange.min)/20  // TODO

        for (let x = 0; x < dateTimes.length - 1; x++)
        {
            const curr = dateTimes[x]
            const next = dateTimes[x + 1]

            if (curr.mod > next.mod)
            {
                verticalLine(x, `rgba(0, 0, 0, .15)`)
                dataCtx.drawText(dateFormat(curr.date, tDiv.format), x, yRange.min - h)
            }
        }
    }

    function createDataDrawingContext()
    {
        const graphPixelSize = getGraphPixelSize(canvas, layout);

        const scaleX = layout.graphPixelsPerPoint;
        const scaleY = -dataWindowSize.height / (yRange.max - yRange.min);

        const translateY = graphId * graphPixelSize.height +
                           graphPixelSize.height - yRange.min * scaleY - layout.xAxisHeight

        return new TransformedDrawingContext(canvas,
            scaleX,
            scaleY,
            layout.yAxisWidth,
            translateY);
    }

    function createGraphDrawingContext()
    {
        const translateY = graphId * getGraphPixelSize(canvas, layout).height

        return new TransformedDrawingContext(canvas, 1, 1, 0, translateY);
    }

}






