import {hBox, wBox} from "./constants";
import {topologyCanvas} from "./main";
import {Position} from "./types";

function drawRoundedRectangle(x: number,
                              y: number,
                              width: number,
                              height: number,
                              radii: number[],
                              fill: string)
{
    topologyCanvas.beginPath();
    topologyCanvas.moveTo(x + radii[0], y);
    topologyCanvas.lineTo(x + width - radii[1], y);
    topologyCanvas.quadraticCurveTo(x + width, y, x + width, y + radii[1]);
    topologyCanvas.lineTo(x + width, y + height - radii[2]);
    topologyCanvas.quadraticCurveTo(x + width, y + height, x + width - radii[2], y + height);
    topologyCanvas.lineTo(x + radii[3], y + height);
    topologyCanvas.quadraticCurveTo(x, y + height, x, y + height - radii[3]);
    topologyCanvas.lineTo(x, y + radii[0]);
    topologyCanvas.quadraticCurveTo(x, y, x + radii[0], y);
    topologyCanvas.closePath();

    topologyCanvas.fillStyle = fill
    topologyCanvas.fill();
}

export function drawRectangle(x: number,
                              y: number,
                              width: number,
                              height: number,
                              fill: string)
{
    topologyCanvas.beginPath();

    topologyCanvas.moveTo(x, y);
    topologyCanvas.lineTo(x + width, y);
    topologyCanvas.lineTo(x + width, y + height);
    topologyCanvas.lineTo(x, y + height);
    topologyCanvas.lineTo(x, y);

    topologyCanvas.closePath();

    topologyCanvas.fillStyle = fill
    topologyCanvas.fill();
}

export function drawBox(position: Position,
                        titleHeight: number,
                        titleFill: string,
                        bodyFill: string,
                        title: string,
                        content: string)
{
    titleHeight = titleHeight * hBox

    const {x, y} = position

    drawRoundedRectangle(x, y, wBox, titleHeight + 1, [5, 5, 0, 0], titleFill)
    drawRoundedRectangle(x, y + titleHeight, wBox, hBox - titleHeight, [0, 0, 5, 5], bodyFill)

    topologyCanvas.font = '13px sans-serif';
    topologyCanvas.textBaseline = 'top';
    topologyCanvas.fillStyle = 'rgb(255,255,255)'

    const wTitle = topologyCanvas.measureText(title).width
    const xTitle = (wBox - wTitle) / 2

    const wContent = topologyCanvas.measureText(content).width
    const xContent = (wBox - wContent) / 2

    topologyCanvas.fillText(title, x + xTitle, y + 5)
    topologyCanvas.fillText(content, x + xContent, y + hBox / 2 + 3)
}