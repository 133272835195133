import {concat, pad} from "./UInt8Utils";

const BigEndian = false

export function sha1Hmac(msg: Uint8Array, key: Uint8Array): Uint8Array
{
    if (key.byteLength > 64)
        key = sha1(key)
    if (key.byteLength < 64)
        key = pad(key, 64)

    const oKey = key.map(b => b ^ 0x5C);
    const iKey = key.map(b => b ^ 0x36);

    const iData = concat(iKey, msg);
    const iHash = sha1(iData);
    const oData = concat(oKey, iHash);

    return sha1(oData);
}

export function sha1(data: Uint8Array): Uint8Array
{
    const paddedData: DataView = initData(data)

    const H = new Uint32Array([0x67452301, 0xEFCDAB89, 0x98BADCFE, 0x10325476, 0xC3D2E1F0])
    const S = new Uint32Array(5)  // State
    const round = new Uint32Array(80);

    function initRound(startOffset: number)
    {
        for (let i = 0; i < 16; i++)
            round[i] = paddedData.getUint32((startOffset + i) * 4, BigEndian);

        for (let i = 16; i < 80; i++)
        {
            const int32 = round[i - 3] ^ round[i - 8] ^ round[i - 14] ^ round[i - 16];
            round[i] = rotate1(int32);  // SHA0 has no rotate
        }
    }

    const functions =
        [
            () => (S[1] & S[2] | ~S[1] & S[3])              + 0x5A827999,
            () => (S[1] ^ S[2] ^ S[3])                      + 0x6ED9EBA1,
            () => (S[1] & S[2] | S[1] & S[3] | S[2] & S[3]) + 0x8F1BBCDC,
            () => (S[1] ^ S[2] ^ S[3])                      + 0xCA62C1D6
        ]

    for (let startOffset = 0; startOffset < paddedData.byteLength / 4; startOffset += 16)
    {
        initRound(startOffset);

        S.set(H)

        for (let r = 0, i = 0; r < 4; r++)
        {
            const f = functions[r]
            const end = i + 20;

            do
            {
                const S0 = rotate5(S[0]) + f() + S[4] + round[i];
                S[4] = S[3];
                S[3] = S[2];
                S[2] = rotate30(S[1]);
                S[1] = S[0];
                S[0] = S0;
            }
            while (++i < end)
        }

        for (let i = 0; i < 5; i++)
            H[i] += S[i]
    }

    swapEndianness(H);

    return new Uint8Array(H.buffer)
}

function rotate5(int32: number)
{
    return (int32 << 5) | (int32 >>> 27);  // >>> for unsigned shift
}

function rotate30(int32: number)
{
    return (int32 << 30) | (int32 >>> 2);
}

function rotate1(int32: number)
{
    return (int32 << 1) | (int32 >>> 31);
}

function initData(data: Uint8Array): DataView
{
    const dataLength     = data.length
    const extendedLength = dataLength + 9;                      // add 8 bytes for UInt64 length + 1 byte for "stop-bit" (0x80)
    const paddedLength   = Math.ceil(extendedLength / 64) * 64; // pad to 512 bits block
    const paddedData     = new Uint8Array(paddedLength)

    paddedData.set(data)
    paddedData[dataLength] = 0x80          // append single 1 bit at end of data

    const dataView = new DataView(paddedData.buffer)

    // append UInt64 length
    dataView.setUint32(paddedData.length - 4, dataLength << 3  , BigEndian)   // dataLength in *bits* LO, (<< 3: x8 bits per byte)
    dataView.setUint32(paddedData.length - 8, dataLength >>> 29, BigEndian)   // dataLength in *bits* HI

    return dataView
}

function swapEndianness(uint32Array: Uint32Array)
{
    const dv = new DataView(uint32Array.buffer)
    for (let i = 0; i < uint32Array.byteLength; i += 4)
    {
        const uint32 = dv.getUint32(i, false)
        dv.setUint32(i, uint32, true)
    }
}

