import {TimeSpan, UnixTime} from "./time";
import {Observable, Subject} from "rxjs";
import {SkipList} from "./skipList/skipList";
import {isUndefined} from "./utils";
import {createDispatchQueue} from "./promiseQueue";
import {SkipListNode} from "./skipList/skipListNode";
import {Maybe} from "./types";

export type FetchResult<T> = T | "N/A" | "Try Later"


function reverseBits(x : number)
{
    // https://stackoverflow.com/a/60227327/141397

    x = (x & 0x55555555)  <<   1 | (x & 0xAAAAAAAA) >>  1;
    x = (x & 0x33333333)  <<   2 | (x & 0xCCCCCCCC) >>  2;
    x = (x & 0x0F0F0F0F)  <<   4 | (x & 0xF0F0F0F0) >>  4;
    x = (x & 0x00FF00FF)  <<   8 | (x & 0xFF00FF00) >>  8;
    x = (x & 0x0000FFFF)  <<  16 | (x & 0xFFFF0000) >> 16;

    return x >>> 0;
}


export default class DataCache<T extends Record<string, number>>
{
    private readonly cache: SkipList<Maybe<T>> =  new SkipList<Maybe<T>>()
    private readonly resolution: TimeSpan;
    private readonly _fetch: (t: UnixTime) => Promise<FetchResult<T>>;

    private readonly fetchQueue = createDispatchQueue(6)
    private readonly fetching: Set<number> = new Set<number>()

    public readonly gotData: Observable<UnixTime>;

    constructor(fetch: (t: UnixTime) => Promise<FetchResult<T>>, resolution: TimeSpan)
    {
        this._fetch = fetch;
        this.resolution = resolution;
        this.gotData = new Subject<UnixTime>()
    }

    public prefetch(times: Array<UnixTime>, clear: boolean = true)
    {
        if (clear)
        {
            this.fetching.clear()
            this.fetchQueue.clear()
        }

        const timesWithPriority = times.map((time, index) => ({time, priority: reverseBits(index)}))
        timesWithPriority.sort((x, y) => x.priority - y.priority)

        for (let i = 0; i < timesWithPriority.length; i++)
        {
            const time = timesWithPriority[i].time.round(this.resolution)
            const t = time.ticks;

            const node = this.cache.find(t);
            if (node.index !== t)
                this.fetchData(time);
        }
    }

    public get(timeStamp: UnixTime): Maybe<T>
    {
        const time = timeStamp.round(this.resolution)
        const t = time.ticks;

        const node = this.cache.find(t);
        if (node.index === t)
            return node.value

        this.fetchData(time);

        return this.interpolate(node, t)
    }

    private interpolate(before: SkipListNode<Maybe<T>>, t: number): Maybe<T>
    {
        const dataBefore = before.value
        const after = before.next[0];
        const dataAfter = after.value

        if (isUndefined(dataBefore) && isUndefined(dataAfter))
            return undefined

        if (isUndefined(dataBefore))
            return dataAfter

        if (isUndefined(dataAfter))
            return dataBefore

        const p = t - before.index
        const n = after.index - t
        const pn = p + n

        let interpolated: Partial<Record<string, number>> = {}

        for (const k of Object.keys(dataBefore))
        {
            interpolated[k] = (dataBefore[k] * n + dataAfter[k] * p) / pn
        }

        return interpolated as T
    }

    private fetchData(time: UnixTime)
    {
        const t = time.ticks;

        if (this.fetching.has(t))  // we are already fetching t
            return

        const fetchTask = () =>
        {
            const onSuccess = (data: FetchResult<T>) =>
            {
                if (data === "Try Later")
                {
                    console.warn("Try Later")
                    return
                }

                const value = data === "N/A" ? undefined : data;
                this.cache.insert(value, t)
            }

            const onFailure = (_: unknown) =>
            {
                console.error(time.ticks + " FAILED!")   // should not happen
            }

            const dispatch = () =>
            {
                this.fetching.delete(time.ticks);
                (this.gotData as Subject<UnixTime>).next(time);
            }

            return this._fetch(time)
                       .then(d => onSuccess(d), f => onFailure(f))
                       .finally(() => dispatch())
        };

        this.fetching.add(t)
        this.fetchQueue.dispatch(() => fetchTask());
    }

}