import {isDefined, useDefault} from "./utils";

export type HslColor = { h: number, s: number, l: number, a?: number }

export function toCssColor(c: HslColor)
{
    const a = useDefault(c.a, 1)
    return `hsl(${c.h % 360}, ${c.s * 100}%, ${c.l * 100}%, ${a})`
}



