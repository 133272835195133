export namespace Utf8
{
    const encoder = new TextEncoder()
    const decoder = new TextDecoder()

    export const encode = (text: string): Uint8Array => encoder.encode(text);
    export const decode = (data: Uint8Array): string => decoder.decode(data);
}


