import {isDefined} from "./utils";

export default class TransformedDrawingContext
{
    private readonly ctx: CanvasRenderingContext2D;
    private readonly canvas: HTMLCanvasElement;

    private readonly scaleX: number;
    private readonly scaleY: number;

    private readonly translateX: number;
    private readonly translateY: number;

    constructor(canvas: HTMLCanvasElement,
                scaleX: number,
                scaleY: number,
                translateX: number,
                translateY: number,
    )
    {
        this.translateY = translateY;
        this.translateX = translateX;
        this.scaleY = scaleY;
        this.scaleX = scaleX;
        this.canvas = canvas;
        this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    }


    public clip(x: number, y: number, w: number, h: number)
    {
        this.ctx.rect(x,y,w,h)
        this.ctx.clip()
    }

    public clear(bgStyle?: string | CanvasGradient | CanvasPattern )
    {
        if (isDefined(bgStyle))
        {
            this.ctx.fillStyle = bgStyle
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
        }
        else
        {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        }
    }


    public beginPath()
    {
        this.ctx.beginPath()
    }

    public closePath()
    {
        this.ctx.closePath()
    }

    public measureText(text:string)
    {
        return this.ctx.measureText(text)
    }


    public moveTo(x: number, y: number)
    {
        this.ctx.moveTo(this.transformX(x), this.transformY(y))
    }

    public lineTo(x: number, y: number)
    {
        this.ctx.lineTo(this.transformX(x), this.transformY(y))
    }

    public fill()
    {
        this.ctx.fill();
    }

    public stroke()
    {
        this.ctx.stroke();
    }

    public save()
    {
        this.ctx.save();
    }

    public restore()
    {
        this.ctx.restore();
    }

    public set fillStyle(style: string | CanvasGradient | CanvasPattern)
    {
        this.ctx.fillStyle = style
    }

    public get fillStyle() : string | CanvasGradient | CanvasPattern
    {
        return this.ctx.fillStyle
    }

    public set textAlign(align: "left" | "right" | "center" | "start" | "end")
    {
        this.ctx.textAlign = align
    }

    public get textAlign() : "left" | "right" | "center" | "start" | "end"
    {
        return this.ctx.textAlign
    }

    public set textBaseline(baseline: "top" | "hanging" | "middle" | "alphabetic" | "ideographic" | "bottom")
    {
        this.ctx.textBaseline = baseline
    }

    public get textBaseline() : "top" | "hanging" | "middle" | "alphabetic" | "ideographic" | "bottom"
    {
        return this.ctx.textBaseline
    }

    public set strokeStyle(style: string | CanvasGradient | CanvasPattern)
    {
        this.ctx.strokeStyle = style
    }

    public get strokeStyle() : string | CanvasGradient | CanvasPattern
    {
        return this.ctx.strokeStyle
    }

    public set lineWidth(width: number)
    {
        this.ctx.lineWidth = width
    }

    public get lineWidth() : number
    {
        return this.ctx.lineWidth
    }

    public set font(f: string)
    {
        this.ctx.font = f
    }

    public get font() : string
    {
        return this.ctx.font
    }

    public strokeCircle(x: number, y: number, radius: number, stroke?: string | CanvasGradient | CanvasPattern)
    {
        if (isDefined(stroke))
            this.ctx.strokeStyle = stroke

        this.ctx.beginPath();
        this.ctx.ellipse(this.transformX(x), this.transformY(y), radius, radius, 0, 0, 2 * Math.PI)
        this.ctx.stroke();
    }

    public fillCircle(x: number, y: number, radius: number, fill?: string | CanvasGradient | CanvasPattern)
    {
        if (isDefined(fill))
            this.ctx.fillStyle = fill

        this.ctx.beginPath();
        this.ctx.ellipse(this.transformX(x), this.transformY(y), radius, radius, 0, 0, 2 * Math.PI)
        this.ctx.fill();
    }

    public drawText(text: string, x:number, y: number)
    {
        this.ctx.fillText(text, this.transformX(x), this.transformY(y))
    }

    public fillRect(x: number, y: number, w: number, h: number)
    {
        this.ctx.fillRect(this.transformX(x), this.transformY(y), w, h)
    }

    public strokeRect(x: number, y: number, w: number, h: number)
    {
        this.ctx.strokeRect(this.transformX(x), this.transformY(y), w, h)
    }

    public transformX(x: number)
    {
        return x * this.scaleX + this.translateX
    }

    public transformY(y: number)
    {
        return y * this.scaleY + this.translateY
    }

    public transformBackX(x: number)
    {
        return (x - this.translateX) / this.scaleX
    }

    public transformBackY(y: number)
    {
        return (y - this.translateY) / this.scaleY
    }

}