import {trim} from "./stringUtils";

export class UnixTime
{
    private constructor(readonly ticks: number)
    {
    }

    public static readonly Epoch = new UnixTime(0)

    public static now(): UnixTime
    {
        return UnixTime.fromTicks(Date.now() / 1000)
    }

    public static fromDate(date: Date): UnixTime
    {
        return UnixTime.fromTicks(date.getTime() / 1000)
    }

    public toDate(): Date
    {
        return new Date(this.ticks * 1000)
    }

    public static fromTicks(ticks: number): UnixTime
    {
        return new UnixTime(ticks)
    }

    public later(timeSpan: TimeSpan): UnixTime
    {
        return new UnixTime(this.ticks + timeSpan.ticks)
    }

    public move(ticks: number): UnixTime
    {
        return new UnixTime(this.ticks + ticks)
    }

    public earlier(timeSpan: TimeSpan): UnixTime
    {
        return new UnixTime(this.ticks - timeSpan.ticks)
    }

    public isEarlierThan(time: UnixTime): boolean
    {
        return this.ticks < time.ticks
    }

    public isEarlierThanOrEqual(time: UnixTime): boolean
    {
        return this.ticks <= time.ticks
    }

    public isLaterThan(time: UnixTime): boolean
    {
        return this.ticks > time.ticks
    }

    public isLaterThanOrEqual(time: UnixTime): boolean
    {
        return this.ticks >= time.ticks
    }

    public isEqual(time: UnixTime): boolean
    {
        return this.ticks === time.ticks
    }

    public isInTheFuture(): boolean
    {
        return this.isLaterThan(UnixTime.now())
    }

    public isInThePast(): boolean
    {
        return this.ticks < UnixTime.now().ticks
    }

    public round(ticks:number) : UnixTime
    public round(duration: TimeSpan) : UnixTime
    public round(durationOrTicks: TimeSpan | number) : UnixTime
    {
        const ticks = (typeof durationOrTicks === "number") ? durationOrTicks : durationOrTicks.ticks

        return new UnixTime(Math.round(this.ticks / ticks) * ticks)
    }

    public rangeTo(time: UnixTime): TimeRange
    {
        return TimeRange.fromTimes(this, time);
    }

    public rangeBefore(timeSpan: TimeSpan): TimeRange
    {
        return TimeRange.fromTimes(this.earlier(timeSpan), this);
    }

    public rangeAfter(timeSpan: TimeSpan): TimeRange
    {
        return TimeRange.fromTimes(this, this.later(timeSpan));
    }

    public toString() : string
    {
        return this.ticks.toString()
    }
}


export class TimeSpan
{
    private constructor(readonly ticks: number) {}

    get seconds(): number { return this.ticks }
    get minutes(): number { return this.ticks / 60 }
    get hours()  : number { return this.minutes / 60 }
    get days()   : number { return this.hours / 24 }
    get weeks()  : number { return this.days / 7 }

    public static fromTicks  (t: number): TimeSpan { return new TimeSpan(t) }
    public static fromSeconds(t: number): TimeSpan { return TimeSpan.fromTicks(t) }
    public static fromMinutes(t: number): TimeSpan { return TimeSpan.fromSeconds(t*60) }
    public static fromHours  (t: number): TimeSpan { return TimeSpan.fromMinutes(t*60) }
    public static fromDays   (t: number): TimeSpan { return TimeSpan.fromHours(t*24) }
    public static fromWeeks  (t: number): TimeSpan { return TimeSpan.fromDays(t*7) }

    public static span(from: UnixTime, to: UnixTime) : TimeSpan
    {
        return TimeSpan.fromTicks(Math.abs(to.ticks - from.ticks))
    }

    public add(timeSpan: TimeSpan) : TimeSpan
    {
        return TimeSpan.fromTicks(this.ticks + timeSpan.ticks)
    }

    public subtract(timeSpan: TimeSpan) : TimeSpan
    {
        return TimeSpan.fromTicks(this.ticks - timeSpan.ticks)
    }

    public divide(n: number) : TimeSpan
    {
        if (n <= 0)
            throw 'n must be positive';

        return TimeSpan.fromTicks(this.ticks/n)
    }

    public multiply(n: number) : TimeSpan
    {
        if (n < 0)
            throw 'n cannot be negative';

        return TimeSpan.fromTicks(this.ticks * n)
    }

    public round(ticks:number) : TimeSpan
    public round(duration: TimeSpan) : TimeSpan
    public round(durationOrTicks: TimeSpan | number) : TimeSpan
    {
        const ticks = (typeof durationOrTicks === "number")
                    ? durationOrTicks
                    : durationOrTicks.ticks

        return TimeSpan.fromTicks(Math.round(this.ticks / ticks) * ticks)
    }


    public toString() : string
    {
        let dt = 60*60*24*7

        let ticks = this.ticks;

        if (ticks === 0)
            return "0s"

        ticks = Math.abs(ticks)

        const nWeeks = Math.floor(ticks / dt)
        ticks -= nWeeks * dt

        dt /= 7
        const nDays = Math.floor(ticks / dt)
        ticks -= nDays * dt

        dt /= 24
        const nHours = Math.floor(ticks / dt)
        ticks -= nHours * dt

        dt /= 60
        const nMinutes = Math.floor(ticks / dt)
        ticks -= nMinutes * dt

        dt /= 60
        const nSeconds = Math.floor(ticks / dt)

        let s = ""

        if (nWeeks > 0)   s += nWeeks  .toString() + "w "
        if (nDays > 0)    s += nDays   .toString() + "d "
        if (nHours > 0)   s += nHours  .toString() + "h "
        if (nMinutes > 0) s += nMinutes.toString() + "m "
        if (nSeconds > 0) s += nSeconds.toString() + "s"

        return trim(s);
    }
}

export class TimeRange
{
    private constructor(private readonly from: number, private readonly to: number)
    {
    }

    public get start(): UnixTime
    {
        return UnixTime.fromTicks(this.from)
    }

    public get mid(): UnixTime
    {
        return UnixTime.fromTicks((this.from + this.to) / 2)
    }


    public get end(): UnixTime
    {
        return UnixTime.fromTicks(this.to)
    }

    public get duration(): TimeSpan
    {
        return TimeSpan.fromTicks(this.to - this.from)
    }

    public static fromTimes(from: UnixTime, to: UnixTime): TimeRange
    {
        return from.isLaterThan(to)
             ? new TimeRange(to.ticks, from.ticks)
             : new TimeRange(from.ticks, to.ticks)
    }

    public isInside(time: number) : boolean;
    public isInside(time: UnixTime) : boolean;
    public isInside(time: UnixTime | number)
    {
        const t = time instanceof UnixTime ? time.ticks : time

        return t >= this.from && t < this.to
    }

    public sample(period: TimeSpan): UnixTime[]
    {
        const samples = []

        for (let t = this.from; t < this.to; t += period.ticks)
            samples.push(UnixTime.fromTicks(t));

        return samples
    }

    public subdivide(n: number) : TimeRange[]
    {
        if (n <= 0)
            throw 'n must be positive';

        const period = TimeSpan.fromTicks(this.duration.ticks / n);
        if (period === this.duration)
            return [this];

        const samples = this.sample(period);

        const ranges : TimeRange[] = []

        for (let i = 0; i < samples.length;)
            ranges.push(TimeRange.fromTimes(samples[i], samples[++i]))

        return ranges
    }


    public earlier(dt: TimeSpan) : TimeRange
    {
        return new TimeRange(this.from - dt.ticks, this.to - dt.ticks)
    }

    public later(dt: TimeSpan) : TimeRange
    {
        return new TimeRange(this.from + dt.ticks, this.to + dt.ticks)
    }

    public move(ticks: number) : TimeRange
    {
        return new TimeRange(this.from + ticks, this.to + ticks)
    }
}

