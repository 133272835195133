import {Position} from "./types";


export const canvasWidth = 1200;
export const canvasHeight = 600;

const u = canvasWidth / 11;
const v = canvasHeight / 7;

const column1 = u;
const column2 = 3*u;
const column3 = 5*u;
const column4 = 7*u;
const column5 = 9*u;

const top = v;
const middle = 3*v;
const bottom = 5*v;

export const wBox = u * .8;
export const hBox = v * .8;
export const wFlow = u / .8;
export const hFlow = v / .8;

export const tMax = 1000000;
export const nElectrons = 700;
export const minElectronSpeed = .8;
export const rElectron = 1.25;
export const pi2 = Math.PI * 2;
export const flowColor = 'rgba(244,179,80,.3)';

export const pGrid:     Position = {x: column1, y: middle};
export const pAcBus:    Position = {x: column2, y: middle};
export const pInverter: Position = {x: column3, y: middle};
export const pDcBus:    Position = {x: column4, y: middle};
export const pBattery:  Position = {x: column5, y: middle};

export const pPv:       Position = {x: pDcBus.x, y: top};
export const pDcLoads:  Position = {x: pDcBus.x, y: bottom};
export const pAcLoads:  Position = {x: pAcBus.x, y: bottom};


